.inner p {
    display: inline;
    line-height: 1.3;
}

.text-container {
    position: relative;
    overflow: hidden;
    display: block;
    font-size: clamp(1.25rem, 2.25rem, 2.75rem);
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    font-style: normal;
    @media screen and (max-width: 768px) {
        font-size: clamp(1.25rem, 1.5rem, 2.25rem);
    }
      @media screen and (max-width: 350px) {
        font-size: clamp(1rem, 1.25rem, 1.5rem);
    }
}

.highlighted-text:before {
    content: attr(data-text);
    display: inline-block;
    opacity: 1;
}

.highlighted-text {
    color: var(--cu-black-900);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 0;
    transition: clip-path 0.2s ease;
    text-shadow: 0 0 0 var(--cu-black-900);
}
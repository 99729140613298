.card {
    transition: all 0.85s ease;

    &:hover {
        .bar {
            opacity: 1;
        }
    }

    &.purple {
        background: linear-gradient(94deg, #A44AFF -3.86%, #9F48F8 7.42%, #9143E6 22.81%, #7A3CC8 40.25%, #5A319F 60.76%, #32246A 81.28%, #0F193C 98.72%);

        &:hover {
            box-shadow: -1dvw 2dvw 3dvw #A44AFF;
        }
    }

    &.blue {
        background: linear-gradient(94deg, #258DF4 -3.86%, #1A579E 40.25%, #122A57 80.25%, #0F193C 98.72%);

        &:hover {
            box-shadow: -1dvw 2dvw 3dvw #258DF4;
        }
    }

    &.red {
        background: linear-gradient(94deg, #F00 -3.86%, #0F193C 98.72%);

        &:hover {
            box-shadow: -1dvw 2dvw 3dvw #F00;
        }
    }

    .bar {
        position: absolute;
        opacity: 0;
        transition: all 0.5s ease;

        &.left-bar {
            left: -5dvw;
            top: 3rem;
            width: 8.8dvw;
            aspect-ratio: 4.4 / 1;

            @media screen and (max-width:768px) {
                left: -2dvw;
            }

            @media screen and (max-width:550px) {
                left: -5dvw;
            }
        }

        &.right-bar {
            bottom: 1rem;
            right: -4dvw;
            width: 5.3dvw;
            aspect-ratio: 2.67 / 1;

            @media screen and (max-width:768px) {
                right: -1dvw;
            }

            @media screen and (max-width:550px) {
                right: -4dvw;
            }
        }

        @media screen and (max-width:550px) {
            width: 19.8dvw !important;
            aspect-ratio: 5.4 / 1 !important;
        }
    }

    .card-image {}

    h3 {
        font-size: clamp(1.75rem, 2.875rem, 3rem);
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -1.84px;
        color: var(--cu-white-900);
        text-align: left;
        @media screen and (max-width:768px) {
            font-size: clamp(1.25rem, 1.75rem, 2rem);
        }
    }
}
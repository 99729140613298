.team-banner {
    position: relative;

    .banner-gradient {
        &:nth-child(1) {
            z-index: 2;
            transform: translate(0%, -33%);
        }

        &:nth-child(2) {
            z-index: 1;
            transform: translate(0%, -13%);
        }

        &:nth-child(3) {
            right: 0;
            left: 0;
            width: 100%;
            top: -18dvw;
        }
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: clamp(3.125rem,6.25rem,6.5rem);
        font-weight: 700;
        line-height: 80%;
        width: 100%;
        letter-spacing: -4px;
        color: var(--cu-white-900);
        z-index: 3;
        
        @media screen and (max-width: 768px) {
            font-size: clamp(2.5rem,3.125rem,3.5rem);
        }
    }

    .banner-image {
        z-index: 3;
        position: relative;
        @media screen and (max-width: 550px) {
           aspect-ratio: 1.75/1;
        }
    }

}
.hero-section-carousel {
  @media screen and (max-width:768px) {
  margin-top: 2rem;
  }
  .mobile-image-block{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin: 5rem 3rem 0rem 3rem;
    @media screen and (max-width:550px) {
      grid-template-columns: repeat(1, 1fr);
      margin: 5rem 2rem 0rem 2rem;
      }
  }
  .slider-section {
    z-index: 2;
    position: relative;
    height: 78dvw; // need to adjust this height based on slider height to avoid the overlapping with bottom section
  }
  .slider {
    height: 40dvw;
    @media screen and (max-width:768px) {
      height: auto;
    }
  }
  .text-wrapper {
    padding-left: 7dvw;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
    aspect-ratio: 1;
    @media screen and (max-width:768px) {
      padding-left: 3rem;
      padding-right: 3rem;
      align-items: start;
      aspect-ratio: unset;
    }
    @media screen and (max-width:768px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    h4 {
      font-size: clamp(2.9rem, 3.75rem, 4rem);
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 60px */
      letter-spacing: -2.4px;
      max-width: 30rem;
      text-align: left;
      @media screen and (max-width:768px) {
        max-width: 100%;
        font-size: clamp(2.9rem, 3.25rem, 3.75rem);
      }
      @media screen and (max-width:550px) {
        width: 70%;
        font-size: clamp(1.5rem, 1.875rem, 2.9rem);
      }
    }
  }

  .slider-floating-image {
    animation: sliderFloatAnimation 3s infinite alternate;
  }

  @keyframes sliderFloatAnimation {
    from {
      transform: translateY(10rem);
    }
    to {
      transform: translateY(-20rem); /* Adjust floating distance as needed */
    }
  }

  .slide-img {
    aspect-ratio: 0.97 / 1;
    padding: 0rem 1.5rem;
    transition: all 3s ease;
    z-index: 1;
    @media screen and (max-width:768px) {
      aspect-ratio: 1;
      padding: 0rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3rem;
      @media screen and (max-width:768px) {
        border-radius: 1rem;
      }
    }
  }
}

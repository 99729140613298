.odd-slide {
  @media screen and (max-width: 550px) {
    margin-bottom: 57%;
    margin-top: 10%;
  }
  .know-more-wrapper {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-left: 6%;
  }
  .gradient {
    &.img1 {
      width: 36%;
      aspect-ratio: 1.1 / 1;
      left: 11%;
      top: 1%;
      @media screen and (max-width: 550px) {
        width: 72%;
        left: -8%;
        top: -19%;
      }
    }
    &.img2 {
      right: 11%;
      top: -2%;
      width: 28%;
      aspect-ratio: 0.81 / 1;
      @media screen and (max-width: 550px) {
        left: 7%;
        bottom: -118%;
        width: 78%;
        aspect-ratio: 1.1 / 1;
        top: unset;
        right: unset;
      }
    }
  }
  .image1 {
    width: 31.7%;
    aspect-ratio: 1.54 / 1;
    left: 20%;
    top: 25%;
    margin-bottom: 10%;
    margin-top: 2%;
    @media screen and (max-width: 550px) {
      width: 75%;
      aspect-ratio: 1.54 / 1;
      left: 2rem;
      top: 0rem;
      margin-bottom: 0rem;
      margin-top: 0rem;
    }
  }
  .image2 {
    width: 29.6%;
    aspect-ratio: 1.41 / 1;
    right: 24%;
    top: 41%;
    position: absolute;
    @media screen and (max-width: 550px) {
      width: 66.6%;
      right: 6%;
      top: 89%;
    }
  }
}

.even-slide {
  @media screen and (max-width: 550px) {
    margin-bottom: 3rem;
  }
  .know-more-wrapper {
    left: 11%;
    top: 76%;
    position: relative;
    z-index: 5;
  }
  .gradient {
    &.img1 {
      width: 48%;
      aspect-ratio: 2 / 1;
      left: 10%;
      top: -33%;
      @media screen and (max-width: 550px) {
        width: 87%;
        aspect-ratio: 1.3 / 1;
        left: -12%;
        top: -8%;
      }
    }
    &.img2 {
      right: 9%;
      top: -87%;
      width: 33%;
      aspect-ratio: 1.34 / 1;
      @media screen and (max-width: 550px) {
        right: -10%;
        top: -83%;
        width: 84%;
      }
    }
  }
  .slider-image {
    border-radius: 2.6rem;
    @media screen and (max-width: 768px) {
      border-radius: 1rem;
    }
  }
  .image1 {
    width: 33.5%;
    aspect-ratio: 2.33 / 1;
    margin-left: 25%;
    margin-top: 9%;
    z-index: 3;
    @media screen and (max-width: 550px) {
      width: 71.5%;
      aspect-ratio: 1.63 / 1;
      margin-left: 0.5rem;
      margin-top: 50%;
      margin-bottom: 0rem;
    }
  }
  .image2 {
    width: 27%;
    aspect-ratio: 1.34 / 1;
    right: 19%;
    bottom: 72%;
    position: absolute;
    @media screen and (min-width: 2000px) {
      bottom: 56%;
    }
    @media screen and (max-width: 550px) {
      width: 66%;
      aspect-ratio: 1.34 / 1;
      right: 2rem;
      bottom: 83%;
    }
  }
}

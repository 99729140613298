.title-and-description-section {
    margin: 15dvw 0rem;
    .title-and-description {
        margin: 0 auto;
        &:nth-child(2) {
            max-width: 47.0625rem;
        }
        &:nth-child(3) {
            max-width: 38.0625rem;
        }
    }
}
.slider-wrapper{
    display: flex;
    .slider-trigger{
        transform: translate(0rem,0rem) !important;
    }
}
.pin-spacer{
    height: auto !important;
    padding: 0 !important;
}

.scroll-content-wrapper{
    transition: all 0.4s ease;
}
  
.block-floating-image {
    animation: blockFloatAnimation 5s infinite alternate; 
  }
  
  @keyframes blockFloatAnimation {
    from {
      transform: translateY(-15dvh);
    }
    to {
      transform: translateY(25dvh); /* Adjust floating distance as needed */
    }
  }
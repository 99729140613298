.cards-list {
    height: 143dvw;

    @media screen and (max-width: 768px) {
        margin: 2rem;
        height: 200dvw;
    }

    @media screen and (max-width: 550px) {
        margin: 4rem 2rem;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .particles {
        position: absolute;
        transition: all 0.85s ease;

        &:first-of-type {
            left: 45dvw;
            top: 31dvw;

            @media screen and (max-width: 550px) {
                left: -3dvw;
                top: 229dvw;
                display: inline-block;
            }
        }

        &:nth-of-type(2) {
            left: 40dvw;
            top: 42dvw;

            @media screen and (max-width: 550px) {
                left: unset;
                right: -6dvw;
                top: 92dvw;
                display: inline-block;
            }
        }

        &:nth-of-type(3) {
            right: 43dvw;
            top: 47dvw;

            @media screen and (max-width: 550px) {
                max-width: 5rem !important;
                display: inline-block;
                right: -7dvw;
                top: 342dvw;
            }
        }

        &:nth-of-type(4) {
            left: 10dvw;
            top: 58dvw;

            @media screen and (max-width: 768px) {
                left: 3dvw;
            }
        }

        &:nth-of-type(5) {
            right: 35dvw;
            top: 61dvw;
        }

        &:nth-of-type(6) {
            right: 18dvw;
            top: 62dvw;

            @media screen and (max-width: 768px) {
                top: 60dvw;
            }
        }

        &:nth-of-type(7) {
            top: 66.5dvw;
            right: 15.5dvw;
        }

        &:nth-of-type(8) {
            left: 11dvw;
            top: 86dvw;

            @media screen and (max-width: 768px) {
                left: 6dvw;
            }
        }

        &:nth-of-type(9) {
            left: 42dvw;
            top: 104dvw;

            @media screen and (max-width: 768px) {
                left: 45dvw;
            }
        }

        &:nth-of-type(10) {
            right: 13dvw;
            top: 107dvw;

            @media screen and (max-width: 768px) {
                right: 10dvw;
            }
        }

        &:nth-of-type(11) {
            left: 36dvw;
            top: 115dvw;

        }

        &:nth-of-type(12) {
            right: 45dvw;
            top: 121dvw;
        }

        &:nth-of-type(13) {
            right: 15dvw;
            top: 116dvw;

            @media screen and (max-width: 768px) {
                right: 10dvw;
                top: 119dvw;

            }
        }

        @media screen and (max-width: 768px) {
            &.md {
                max-width: 2rem;
            }

            &.sm {
                max-width: 1rem;
            }

            &.lg {
                max-width: 3rem;
            }
        }

        @media screen and (max-width: 550px) {
            &.md {
                max-width: 3.1875rem;
                width: 100%;
            }

            &.sm {
                max-width: 4.25rem;
                width: 100%;
            }

            &.lg {
                max-width: 3rem;
            }
        }

        @media screen and (max-width: 550px) {
            display: none;
            width: 100%;
        }
    }

    .team-card {
        position: absolute;
        z-index: 2;

        @media screen and (max-width: 550px) {
            position: static;

            &:nth-of-type(odd) {
                align-items: end;
            }

            &:nth-of-type(even) {
                align-items: start;
            }
        }

        &:first-of-type {
            left: 18dvw;

            @media screen and (max-width: 768px) {
                left: 6dvw;
            }
        }

        &:nth-of-type(2) {
            right: 21dvw;
            top: 10dvw;

            @media screen and (max-width: 768px) {
                right: 6dvw;
            }
        }

        &:nth-of-type(3) {
            left: 26dvw;
            top: 54dvw;

            @media screen and (max-width: 768px) {
                left: 7dvw;
                top: 70dvw;
            }
        }

        &:nth-of-type(4) {
            right: 13dvw;
            top: 77dvw;

            @media screen and (max-width: 768px) {
                right: -4dvw;
                top: 74dvw;
            }
        }

        &:nth-of-type(5) {
            left: 10dvw;
            top: 102dvw;

            @media screen and (max-width: 768px) {
                left: 6dvw;
                top: 134dvw;
            }
        }
    }
}
.footer {
  // min-height: 75dvh;
    margin: 15rem 0rem 5rem 0rem;
    .footer-link-wrapper{
      .footer-link{
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        line-height: 1.5rem;
        letter-spacing: 2.8px;
        text-transform: uppercase;
        transition: all 0.5s ease;
        scale: 0.9;
        &:hover{
          scale: 1;
        }
      }
    }
  @media screen and (max-width:768px) {
    min-height: auto;
    margin: unset;
  }
  .floating-image {
    // position: fixed;
    height: 96dvh;
    width: 40dvw;
    animation: floatAnimation 3s infinite alternate; /* Adjust animation duration as needed */
    @media screen and (max-width:768px) {
      animation: floatAnimationMob 3s infinite alternate;
    }
  }
  
  @keyframes floatAnimation {
    from {
      transform: translateY(-40dvh);
    }
    to {
      transform: translateY(-20dvh); /* Adjust floating distance as needed */
    }
  }

  @keyframes floatAnimationMob {
    from {
      transform: translateY(-30rem);
    }
    to {
      transform: translateY(-20rem); /* Adjust floating distance as needed */
    }
  }
  
  
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 3.75rem;
    z-index: 2;
    position: relative;
    .footer-logo{
      @media screen and (max-width:768px) {
        width: 5rem;
      }
    }
    .social-media {
      a {
        width: 1.5rem;
        height: 1.5rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .tabs {
      gap: 2.5rem;
      @media screen and (max-width:550px) {
        flex-direction: column;
        gap: 1.5rem;
    }
      span {
        font-size: clamp(0.875rem, 1.115rem, 1.35rem);
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 2.52px;
        @media screen and (max-width:550px) {
          font-size: clamp(0.5rem, 0.625rem, 1rem);
      }
      }
    }
    p {
      font-size: clamp(0.75rem, 0.875rem, 1rem);
      font-style: normal;
      font-weight: 700;
      text-align: center;
      line-height: 1.5rem;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      @media screen and (max-width:550px) {
        font-size: clamp(0.4rem, 0.5rem, 0.625rem);
    }
    }
  }
}

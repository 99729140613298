@import "../Styles/main.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@mixin mobile_screen {
  @media (max-width: 767px) {
    @content;
  }
}

// @include mobile_screen {
.App {
  text-align: center;
  gap: 10px;
  // color: var(--cu-white-900);
  // overflow: hidden;

  section {
    min-height: 100dvh;
    height: 100%;
  }

  .border-rad-10 {
    border-radius: 10px;
  }
  .grey-border {
    border: 1px solid var(--cu-black-100);
  }
  .border-y-0 {
    border-bottom: 0;
    border-top: 0;
  }

  .font-10 {
    font-size: 0.625rem;
  }

  .font-12 {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  .font-14 {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  ::-webkit-scrollbar {
    width: 0.1rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 1rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--cu-grey-650);
    border: 1px solid transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.purple-100-bg {
  background: var(--cu-purple-100);
}
.red-700-bg {
  background: var(--cu-red-700);
}

.mobile-normal {
  @media screen and (max-width: 1024px) {
    min-height: auto !important;
  }
}

.blue-red-gradient-button {
  border-radius: 0.625rem;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  background: var(
    --Gradients-Main-Brand,
    linear-gradient(
      94deg,
      #258df4 -3.86%,
      #417df6 7.42%,
      #8858fc 36.14%,
      #a44aff 49.48%,
      #a648f8 53.58%,
      #ac42e6 59.74%,
      #b73ac8 66.92%,
      #c62e9f 75.12%,
      #d91e69 84.36%,
      #ef0c2a 93.59%,
      #f00 98.72%
    )
  );
  &:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;
    transition: all 0.3s ease;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  &:hover {
    color: #fff;
    border: none;
    background: transparent;
  }

  &:hover:after {
    background: linear-gradient(
      94deg,
      #258df4 -3.86%,
      #417df6 7.42%,
      #8858fc 36.14%,
      #a44aff 49.48%,
      #a648f8 53.58%,
      #ac42e6 59.74%,
      #b73ac8 66.92%,
      #c62e9f 75.12%,
      #d91e69 84.36%,
      #ef0c2a 93.59%,
      #f00 98.72%
    );
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

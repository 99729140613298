.title-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  background: transparent;
  width: 100%;
  min-height: 5.625rem;
  padding: 1.25rem;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  .navbar-tabs {
    pointer-events: auto;
    padding: 0rem 0rem 0rem 2.5rem;
    border-radius: 0.625rem;
    border: 1px solid var(--cu-grey-trans-100);
    background: var(--cu-grey-trans-200);
    backdrop-filter: blur(0.625rem);
    .tab-link {
      cursor: pointer;
      font-size: 0.875rem;
      color: var(--cu-black-900);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      position: relative;
      &.active{
        font-weight: 700; 
        &::after{
          height: 1.5px;
          width: 100%;
          content: "";
          position: absolute;
          bottom: -2px;
          background: var(--cu-black-900);
          left: 0rem;
        }
      }
      &.inactive{
        &::after{
          background: none repeat scroll 0 0 transparent;
          bottom: -2px;
          content: "";
          display: block;
          height: 1.5px;
          left: 50%;
          position: absolute;
          background: var(--cu-black-900);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
        &:hover{
          font-weight: 700; 
          color: var(--cu-black-900);
          &::after { 
          width: 100%; 
          left: 0; 
        }}
      }
    }
  }
  .logo-wrapper {
    pointer-events: auto;
    padding: 0.625rem 1.25rem;
    border-radius: 0.625rem;
    border: 1px solid var(--cu-grey-trans-100);
    background: var(--cu-grey-trans-200);
    backdrop-filter: blur(0.625rem);
    img.expanded-logo {
      // height: auto;
      // width: 9rem;
      visibility: visible;
      opacity: 1;
      // @media screen and (max-width: 550px) {
      //   height: 100%;
      //   margin-left: 0rem;
      // }
    }
  }
  .logo-box {
    padding: 0.5625rem;
    border-radius: 0.85rem;
    border: 1.36px solid rgba(204, 204, 204, 0.7);
    background: rgba(204, 204, 204, 0.2);
    backdrop-filter: blur(0.85rem);
    img {
      width: 2.25rem;
      height: 2.25rem;
    }
  }
  .menu-collapse {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0.625rem;
    border: 1px solid rgba(204, 204, 204, 0.7);
    background: rgba(204, 204, 204, 0.2);
    backdrop-filter: blur(10px);
    padding: 3rem 2.875rem;
    transition: all 0.75s ease;
    transform: translateX(800px);
    opacity: 0;
    &.active {
      transform: translateX(0px);
      opacity: 1;
    }
    .scroll-menu-div {
      flex-direction: column;
      gap: 2rem;
      align-items: start;
      .navbar-tabs {
        background: transparent;
        backdrop-filter: unset;
        border: none;
        padding: 0;
        .tabs-wrapper {
          flex-direction: column;
          align-items: start;
        }
      }
    }
  }
}
.mixed-gradient-btn {
  display: flex;
  position: relative;
  padding: 0.875rem 1.875rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.625rem;
  color: var(--cu-white-900);
  overflow: hidden;
  transition: all 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  background: linear-gradient(
    94deg,
    rgba(37, 141, 244, 0.6) -3.86%,
    rgba(65, 125, 246, 0.6) 7.42%,
    rgba(136, 88, 252, 0.6) 36.14%,
    rgba(164, 74, 255, 0.6) 49.48%,
    rgba(166, 72, 248, 0.6) 53.58%,
    rgba(172, 66, 230, 0.6) 59.74%,
    rgba(183, 58, 200, 0.6) 66.92%,
    rgba(198, 46, 159, 0.6) 75.12%,
    rgba(217, 30, 105, 0.6) 84.36%,
    rgba(239, 12, 42, 0.6) 93.59%,
    rgba(255, 0, 0, 0.6) 98.72%
  );
  &:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;
    transition: all 0.3s ease;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  &:hover {
    color: #fff;
    border: none;
    background: transparent;
  }
  &:hover:after {
    background: linear-gradient(
      94deg,
      rgba(37, 141, 244, 0.6) -3.86%,
      rgba(65, 125, 246, 0.6) 7.42%,
      rgba(136, 88, 252, 0.6) 36.14%,
      rgba(164, 74, 255, 0.6) 49.48%,
      rgba(166, 72, 248, 0.6) 53.58%,
      rgba(172, 66, 230, 0.6) 59.74%,
      rgba(183, 58, 200, 0.6) 66.92%,
      rgba(198, 46, 159, 0.6) 75.12%,
      rgba(217, 30, 105, 0.6) 84.36%,
      rgba(239, 12, 42, 0.6) 93.59%,
      rgba(255, 0, 0, 0.6) 98.72%
    );
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


.close-icon{
    border: 1px solid rgba(204, 204, 204, 0.7);
    background: rgba(204, 204, 204, 0.2);
    backdrop-filter: blur(10px);
    padding: 0.5rem;
    border-radius: 0.625rem;
   svg{
    &:hover{
       opacity: 0.5;
    }
   } 
}
.block-wrapper {
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .glass-box {
    position: absolute;
    width: 10.5dvw;
    aspect-ratio: 1/1;
    height: auto;
    transition: all 0.75s ease;
    @media screen and (max-width: 768px) {
      width: 20.5dvw;
    }
    &.mobile-block-glass-box{
      transition: all 0.75s ease;
      right: 1rem;
      top: -22%;
    }
  }
  &:nth-child(odd) {
    .image-box {
      margin-right: 2rem;
      @media screen and (max-width: 768px) {
        margin-right: 0rem;
      }
      .glass-box {
        bottom: -17%;
        left: -18%;
        @media screen and (max-width: 768px) {
          bottom: -14%;
          left: -14%;
        }
      }
    }
  }
  &:nth-child(even) {
    .image-box {
      margin-left: 2rem;
      @media screen and (max-width: 768px) {
        margin-left: 0rem;
      }
      .glass-box {
        bottom: -17%;
        right: -18%;
        @media screen and (max-width: 768px) {
          bottom: -14%;
          right: -14%;
        }
      }
    }
    .image-main-wrapper {
      -webkit-order: 2;
      order: 2;
      justify-content: left;
      @media screen and (max-width: 768px) {
        -webkit-order: 1;
        order: 1;
        justify-content: center;
      }
    }

    .text-wrapper {
      width: 50%;
      padding-left: 0rem;
      padding-right: 5dvw;
      display: flex;
      justify-content: end;
      @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: center;
        padding-left: 3rem;
        padding-right: 3rem;
        order: 2;
      }
      @media screen and (max-width: 550px) {
        padding-left: 0rem;
        padding-right: 0rem;
      }
      .glass-box {
        display: none;
      }
    }
  }

  &:is(:first-child, :nth-child(4)) {
    .image-box {
      background: linear-gradient(
        94deg,
        #a44aff -3.86%,
        #9f48f8 7.42%,
        #9143e6 22.81%,
        #7a3cc8 40.25%,
        #5a319f 60.76%,
        #32246a 81.28%,
        #0f193c 98.72%
      );
    }
  }
  &:nth-child(2) {
    .image-box {
      background: linear-gradient(
        94deg,
        #258df4 -3.86%,
        #1a579e 40.25%,
        #122a57 80.25%,
        #0f193c 98.72%
      );
    }
  }
  &:is(:nth-child(3), :nth-child(5)) {
    .image-box {
      background: linear-gradient(94deg, #f00 -3.86%, #0f193c 98.72%);
    }
  }

  .image-box {
    border-radius: 1.25rem;
    position: relative;
    padding: 1.2rem;
    width: 30dvw;
    aspect-ratio: 1 / 1;
    @media screen and (max-width: 768px) {
      width: 66dvw;
      padding: 0.75rem;
      border-radius: 0.75rem;
    }
    .block-img {
      border-radius: 1.15rem;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        border-radius: 0.625rem;
      }
    }
  }
  .text-wrapper {
    width: 40%;
    position: relative;
    padding-left: 5dvw;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-left: 0rem;
      margin-top: 2rem;
    }
    .glass-box {
      top: 0%;
      right: 0%;
    }
    .heading-desc-wrapper {
      max-width: 21.375rem;
      transition: all 0.75s ease;
      @media screen and (max-width: 768px) {
        max-width: 100%;
        align-items: center;
      }
      h2 {
        color: var(--cu-blue-dark);
        font-size: clamp(2.5rem, 3.75rem, 4rem);
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -2.4px;
        text-align: left;
        @media screen and (max-width: 768px) {
          text-align: center;
          font-size: clamp(2.5rem, 3rem, 3.5rem);
        }
        @media screen and (max-width: 550) {
          font-size: clamp(2.25rem, 2.5rem, 3rem);
        }
      }
      p {
        font-size: clamp(1.375rem, 1.5rem, 1.75rem);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        @media screen and (max-width: 768px) {
          text-align: center;
          font-size: clamp(1.25rem, 1.375rem, 1.5rem);
        }
      }
    }
  }
}

.social-media-button-section {
  .blue-red-gradient-button {
    max-width: 18.8rem;
    width: 100%;
    padding: 0.75rem 0.5rem;
    color: var(--cu-white-900);
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
}

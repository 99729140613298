.hero-page {
.hero-dummy-img{
  position: absolute;
  inset: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100dvh;
  position: relative;
  .background-video{
    position: absolute;
    inset: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  &::after {
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 73%);
    width: 100%;
    height: 16%;
    position: absolute;
    @media screen and (max-width: 560px) {
      &::after {
      height: 10%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    background-position: center;
  }
  .content {
    @media screen and (max-width: 768px) {
      gap: 1rem;
    }
    .down-arrow {
      width: 4.687rem;
      aspect-ratio: 1;
      transition: scale 1s ease;
      @media screen and (max-width: 550px) {
        width: min(3.5rem, 3.9rem);
      }
    }

    max-width: 23.5rem;
    h1 {
      line-height: 5rem;
      font-size: clamp(2rem, 3.75rem, 4rem);
      font-style: normal;
      color: var(--cu-white-900);
      transform: scale(0.94);
      opacity: 0;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        font-size: clamp(2rem, 3.25rem, 3.75rem);
        line-height: 3.5rem;
      }
      @media screen and (max-width: 550px) {
        font-size: clamp(2rem, 2.9rem, 3rem);
        font-weight: 400;
        line-height: 3rem;
      }
      &.animation-h1 {
        animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
        @keyframes scale {
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
        span {
          display: inline-block;
          opacity: 0.2;
          filter: blur(5px);
        }

        span:nth-child(1) {
          animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(2) {
          animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(3) {
          animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(4) {
          animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(5) {
          animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(6) {
          animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(7) {
          animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(8) {
          animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(9) {
          animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(10) {
          animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(11) {
          animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(12) {
          animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(13) {
          animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(14) {
          animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(15) {
          animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(16) {
          animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(17) {
          animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }

        span:nth-child(18) {
          animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(19) {
          animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(20) {
          animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(21) {
          animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(22) {
          animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(23) {
          animation: fade-in 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(24) {
          animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(25) {
          animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(26) {
          animation: fade-in 0.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(27) {
          animation: fade-in 0.8s 2.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(28) {
          animation: fade-in 0.8s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(29) {
          animation: fade-in 0.8s 2.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(30) {
          animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        span:nth-child(31) {
          animation: fade-in 0.8s 3.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        @keyframes fade-in {
          100% {
            scale: 1.3;
          }
          100% {
            opacity: 1;
            scale: 1;
            filter: blur(0);
          }
        }
      }
    }
    p {
      font-size: clamp(1rem, 1.5rem, 2rem);
      font-style: normal;
      overflow: hidden; /* Hide overflow to create the typewriter effect */
      white-space: nowrap; /* Prevent text wrapping */
      // transform: scale(0.94);
      // opacity: 0;
      color: var(--cu-white-900);
      @media screen and (max-width: 550px) {
        font-size: clamp(1rem, 1.3rem, 1.5rem);
      }
    }
    .animation-desc {
      p {
        animation: typing 2s steps(60, end), blink-caret 0.75s infinite; /* Animation */
      }
    }

    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
  }
}

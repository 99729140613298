.creators-discovered {
    // aspect-ratio: 1.62 / 1;
    margin-bottom: 2rem;
    @media screen and (max-width: 768px) {
      padding: 0rem 2rem;
      display: flex;
      flex-direction: column;
    }
    .text-section {
      z-index: 2;
      transition: all 0.85s ease;
      @media screen and (max-width: 768px) {
        padding: 2.56rem 1.5rem;
        max-width: 100%;
        flex-direction: column;
        gap: 1rem;
        order: 1;
        border-radius: 1.5rem;
        aspect-ratio: unset;
        top: unset;
        bottom: -1.25rem;
        transform: none !important;

      }
      .right-bar {
        right: -1rem;
        top: 2rem;
        transition: all 0.85s ease;
        @media screen and (max-width: 768px) {
          right: -1.5rem;
          top: 1rem;
          width: 7.375rem;
          aspect-ratio: 09 / 1;
          transform: none !important;
        }
      }
      .left-bar {
        bottom: -5rem;
        left: -7rem;
        transition: all 0.85s ease;
        @media screen and (max-width: 768px) {
          bottom: 1rem;
          left: -1.5rem;
          width: 6.375rem;
          transform: none !important;

        }
      }
      padding: 4rem 7rem;
      gap: 6.25rem;
      position: relative;
      top: -10dvw;
      border-radius: 3.125rem;
      background: var(--cu-white-900);
      box-shadow: 0px 4px 36px 0px var(--cu-grey-trans-300);
      max-width: 86dvw;
      aspect-ratio: 3 / 1;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      h2 {
        font-size: clamp(3rem, 3.75rem, 4rem);
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -2.4px;
        color: var(--cu-black-900);
        text-align: left;
        z-index: 1;
        @media screen and (max-width: 768px) {
          z-index: 2;
        }
        @media screen and (max-width: 550px) {
          font-size: clamp(2.5rem, 2.9rem, 3rem);
        }
      }
      p {
        color: var(--cu-black-900);
        font-size: clamp(1.3rem, 1.5rem, 1.75rem);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-align: left;
        @media screen and (max-width: 768px) {
          z-index: 2;
        }
        @media screen and (max-width: 550px) {
          z-index: 2;
          font-size: clamp(1rem, 1.3rem, 1.5rem);
        }
      }
    }
    .img-wrapper {
      transition: all 0.85s ease;
      width: 69dvw;
      margin-right: auto;
      aspect-ratio: 1.788 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-top: 5rem;
      @media screen and (max-width: 768px) {
        width: 100%;
        padding-top: 0rem;
        border-radius: 0.75rem;
        transform: none !important;

      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 3.125rem;
        @media screen and (max-width: 768px) {
          border-radius: 0.75rem;
  
        }
      }
    }
  }
  
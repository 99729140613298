.app-items-cards {
    border-radius: 1.25rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    aspect-ratio: 0.97 / 1;
    padding: 1rem;
    transition: all 0.5s ease;
  
    .icon-wrapper {
        img {
            transition: all 0.5s ease;
            position: relative;
            transform: translate(-50%, 50%);
            left: 50%;
            bottom: 50%;
            width: 100%;
            height: 100%;
        }
        @media screen and (max-width: 550px) {
            max-width: 11dvw;
        }
    }

    h5 {
        color: var(--cu-white-900);
        font-weight: 700;
        line-height: 110%;
        display: none;
        transition: all 0.5s ease;
    }

    &:hover {
        h5 {
            display: inline-block;
        }

        .icon-wrapper {
            img {
                transform: translate(-50%, 45%);
            }
        }
    }

    &.sm {
        width: 8dvw;
        @media screen and (max-width: 550px) {
            width:27dvw;
        }
        h5 {
            font-size: clamp(0.5rem, 0.85rem, 1.5rem);
            @media screen and (max-width: 550px) {
                font-size: clamp(0.5rem, 0.85rem, 1.5rem);  
            }
        }
    }

    &.xl {
        width: 22.75dvw;
        @media screen and (max-width: 550px) {
            width: 42.5dvw;
        }
        h5 {
            font-size: clamp(0.75rem, 2.25rem, 3rem);
            @media screen and (max-width: 550px) {
                font-size: clamp(0.5rem, 1.25rem, 2rem);  
            }
        }
    }

    &.md {
        width: 12.1dvw;
        @media screen and (max-width: 550px) {
            width:27dvw;
        }
        h5 {
            font-size: clamp(0.7rem, 1.25rem, 2rem);
            @media screen and (max-width: 550px) {
                font-size: clamp(0.7rem, 1rem, 2rem);
            }
        }
    }

    &.lg {
        width: 15.85dvw;
        @media screen and (max-width: 550px) {
            width: 42.5dvw;
        }
        h5 {
            font-size: clamp(1rem, 1.75rem, 2.5rem);
            @media screen and (max-width: 550px) {
                font-size: clamp(1rem, 1.5rem, 2rem);
            }
        }
    }

}
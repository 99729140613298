.video-and-text-wrapper {
  background-image: url("../../../../../Assets/Images/home/gradient-video-lg-bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // height: 237dvh;
  padding: 20rem 0rem;
  @media screen and (max-width: 768px) {
    // height: 195dvh;
  }
  @media screen and (max-width: 560px) {
    // height: 170dvh;
    padding: 16rem 0rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  .content {
    margin-top: 5.4rem;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
      flex-direction: column;
    }
    p {
      color: var(--cu-white-900);
      font-size: clamp(1rem, 1.5rem, 1.75rem);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      transition: all 0.85s ease;
      max-width: 24.375rem;
      margin-left: 4dvw;
      @media screen and (max-width: 768px) {
        margin-left: 0rem;
        max-width: 100%;
        font-size: clamp(0.75rem, 1rem, 1.5rem);
      }
      @media screen and (max-width: 560px) {
        font-size: clamp(0.75rem, 1rem, 1.5rem);
      }
    }
    .video-content {
      width: 37.3dvw;
      aspect-ratio: 1 / 0.86;
      margin: auto;
      position: relative;
      z-index: 3;
      transition: all 0.85s ease;
      img {
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        aspect-ratio: 1 / 0.7;
      }
    }
  }
  .heading {
    padding: 0rem 3rem;
    @media screen and (max-width: 550px) {
      padding: 0rem 2rem;
    }
    h1 {
      line-height: 11rem;
      color: var(--cu-white-900);
      font-size: clamp(4.18rem, 11.125rem, 11.25rem);
      font-style: normal;
      font-weight: 400;
      letter-spacing: -7.149px;
      max-width: 68.75rem;
      margin: auto;
      transition: all 0.85s ease;
      @media screen and (max-width: 768px) {
        font-size: clamp(4.18rem, 7.125rem, 11rem);
        line-height: 7rem;
        letter-spacing: -4px;
      }
      @media screen and (max-width: 550px) {
        font-size: clamp(4rem, 4.1875rem, 6rem);
        line-height: 4.3rem;
      }
      &:first-child {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: right;
      }
      &:nth-child(3) {
        text-align: left;
        position: relative;
        left: 12%;
      }
    }
    h4 {
      margin-top: 3rem;
      color: var(--cu-white-900);
      font-size: clamp(1.43rem, 2.25rem, 2.5rem);
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      position: relative;
      overflow: hidden;
      transition: all 0.85s ease;
      @media screen and (max-width: 768px) {
        margin-top: 2rem;
        transition: all 0.85s ease;
        font-size: clamp(1rem, 1.5rem, 2rem);
      }
      @media screen and (max-width: 560px) {
        font-size: clamp(0.75rem, 1rem, 2rem);
      }
    }
  }
}

.heading-and-image-block {
  transition: all 0.85s ease;
  z-index: 1;
  @media screen and (max-width:768px) {
    transform: none !important;
    gap:0.25rem;
  }
  h1 {
    font-size: clamp(2.9rem, 5.25rem, 6.5rem);
    color: var(--cu-white-900);
    text-align: left;

 
    @media screen and (max-width:768px) {
      font-size: clamp(2rem, 2.9rem, 3rem);
      font-weight: 500;
      text-align: center;
    }
    @media screen and (max-width:550px) {
      font-size: clamp(1.25rem, 1.75rem, 2.5rem);
    }
  }

  .image-content {
    width: 50.6dvw;
    aspect-ratio: 1.78 / 1;
    border-radius: 3.125rem;
    object-fit: cover;

    @media screen and (max-width:768px) {
      width: 100%;
      aspect-ratio: 1.33/1;
      border-radius: 1.1rem;
    }
  }

  .link-btn {
    padding: 0.625rem 1.75rem;
    text-transform: uppercase;
    position: absolute;
    color: var(--cu-white-900);
    transition: all 0.5s ease;
    border-radius: 1.25rem;
    font-weight: 700;
    letter-spacing: 1.6px;
    border: 1px solid transparent;

    &:hover {
      background: var(--cu-white-900) !important;
    }

    @media screen and (max-width:768px) {
      position: relative;
      font-size: clamp(0.45rem,0.5rem,0.75rem);
      padding: 0.25rem 0.75rem;
      width: fit-content;
      margin: 1rem auto 0rem auto;
      transform: none !important;
    }
  }

  &:nth-child(odd) {
    margin-right: auto;

    .link-btn {
      bottom: 10dvw;
      right: -5.5rem;
      background: var(--cu-blue-navy);

      @media screen and (max-width:768px) {
        bottom: unset;
        right: unset;
      }

      &:hover {
        color: var(--cu-blue-navy);
        border: 1px solid var(--cu-blue-navy);
      }
    }
  }

  &:nth-child(even) {
    margin-left: auto;

    h1 {
      order: 1;
      @media screen and (max-width:768px) {
        order: 0;
      }
    }

    .link-btn {
      background: var(--cu-red-700);
      top: 21dvw;
      left: -10rem;

      @media screen and (max-width:768px) {
        top: unset;
        left: unset;
      }

      &:hover {
        color: var(--cu-red-700);
        border: 1px solid var(--cu-red-700);
      }
    }
  }
}
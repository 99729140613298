.carousel {
  // overflow: hidden;
  width: 100%;
  //  min-height: 125dvh;
   .slider-section{
    // min-height: 77dvw;
   }
  @media screen and (max-width: 1024px) {
    // min-height: 55rem;
  }
  .slide {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;
    &.active {
      opacity: 1;
    }
  }
  @media screen and (max-width: 550px) {
    padding: 6rem 2rem;
  }
  .slider-arrow {
    aspect-ratio: 1 / 1;
    max-width: 9rem;
    @media screen and (max-width: 768px) {
      max-width: 4rem;
    }
  }
  .know-more-btn {
    font-size: clamp(0.75rem, 1rem, 1.25rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    border-radius: 2rem;
    display: flex;
    color: var(--cu-white-900);
    padding: 0.625rem 1.75rem;
    justify-content: center;
    align-items: center;
    min-width: calc(12.93rem - 1.5rem);
    transition: all 0.5s ease;
    border: 2px solid transparent;
    @media screen and (max-width: 768px) {
      font-size: clamp(0.45rem, 0.75rem, 1rem);
      padding: 0.5rem 1.75rem;
      min-width: calc(10.93rem - 1.25rem);
    }
    @media screen and (max-width: 768px) {
      font-size: clamp(0.45rem, 0.5rem, 1rem);
      padding: 0.35rem 0rem;
      min-width: calc(8.93rem - 0.75rem);
    }
    &.purple-100-bg {
      &:hover {
        background: transparent;
        border: 2px solid var(--cu-purple-100);
        color: var(--cu-purple-100);
      }
    }
    &.red-700-bg {
      &:hover {
        background: transparent;
        border: 2px solid var(--cu-red-700);
        color: var(--cu-red-700);
      }
    }
  }

  .image1,
  .image2 {
    position: relative;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  h2 {
    font-size: clamp(2.93rem, 6.25rem, 6.5rem);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -4px;
    margin-bottom: 1.6rem;
    @media screen and (max-width: 768px) {
      font-size: clamp(2rem, 2.5rem, 4rem);
    }
    @media screen and (max-width: 550px) {
      font-size: clamp(2rem, 2.9rem, 3rem);
      margin-bottom: 0rem;
    }
  }
  button {
    z-index: 11;
    
  img{
    transition: all 0.5s ease;
    &:is(:hover,:focus){
      scale: 0.8;
    }
  }
    &.pre {
      margin-left: 21%;
    }
    &.next {
      margin-right: 28%;
    }
  }
  .gradient {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }


}

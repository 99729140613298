.about-hero {
    .particles {
        z-index: 1;
        position: absolute;
        transition: all 0.85s ease;
    }

    .text-wrapper {
        .particles {
            &:first-of-type {
                left: 45dvw;
                bottom: 13dvw;
                @media screen and (max-width: 768px) {
                    max-width: 2.5rem;
                    left: -1rem;
                    bottom: -5rem;
                  }
            }

            &:nth-of-type(2) {
                left: 40dvw;
                bottom: 1dvw;
                @media screen and (max-width: 768px) {
                    max-width: 3.75rem;
                    left: unset;
                    right: -1rem;
                    bottom: -3rem;
                  }
            }
        }
    }

    h1 {
        font-size: clamp(3.375rem, 7.5rem, 10rem);
        color: var(--cu-black-900);
        line-height: 99.2%;
        letter-spacing: -4.833px;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            font-size: clamp(3rem, 3.375rem, 4rem);
          }
    }

    .hero-image-wrapper {
        .hero-img {
            border-radius: 3.125rem;
            width: 21.6dvw;
            aspect-ratio: 0.52 / 1;
            @media screen and (max-width: 768px) {
                width: 100%;
                aspect-ratio: 1.5/1;
                border-radius: 1.5rem;
              }
        }

        .particles {
            left: -3.4dvw;
            bottom: -1dvw;
            @media screen and (max-width: 768px) {
              max-width: 3.3125rem;
              aspect-ratio: 1;
              left: unset;
              right: -3.4dvw;
              }
        }
    }

    p {
        font-size: clamp(1rem, 1.5rem, 2rem);
        color: var(--cu-black-900);
        line-height: 140%;
        max-width: 23rem;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 768px) {
            max-width: 100%;
            }
    }
}
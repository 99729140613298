.brief-floating-image {
    // position: fixed;
    animation: briefFloatAnimation 3s infinite alternate; /* Adjust animation duration as needed */
  }
  
  @keyframes briefFloatAnimation {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(25rem); /* Adjust floating distance as needed */
    }
  }
  

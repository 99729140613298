.contact-hero {
  .text-wrapper {
    padding-top: 10rem;
    h1 {
      text-align: center;
      font-size: clamp(4.75rem, 10.875rem, 11rem);
      color: var(--cu-black-900);
      line-height: 99.2%;
      letter-spacing: -7.149px;
      transition: all 0.85s ease;
      @media screen and (max-width:768px) {
        font-size: clamp(4rem, 4.75rem, 5rem);
      }
    }
    p {
      font-size: clamp(1.4rem, 2.5rem, 3rem);
      color: var(--cu-black-900);
      line-height: 100%;
      letter-spacing: -1.6px;
      font-weight: 500;
      margin-top: 5rem;
      margin-bottom: 1rem;
      @media screen and (max-width:768px) {
        font-size: clamp(1rem, 1.4rem, 2rem);
      }
      @media screen and (max-width:550px) {
        max-width: 18rem;
        margin: 5rem auto 1rem auto;
      }
    }
  }
}

.landing-page {
  .brief {
    color: var(--cu-black-900);
    font-size: clamp(1.25rem, 2.25rem, 2.75rem);
    font-style: normal;
    font-weight: 500;
    max-width: 39.75rem !important;
    margin: auto 1.5rem !important;
    line-height: 120%;
    transition: all 0.85s ease;
    @media screen and (max-width: 1024px) {
      margin-top: 3rem !important;
    }
    @media screen and (max-width: 768px) {
      font-size: clamp(1.25rem, 1.5rem, 2.25rem);
    }
    @media screen and (max-width: 550px) {
      font-size: clamp(1rem, 1.25rem, 1.5rem);
    }
  }
}

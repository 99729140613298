.about-us {
  // aspect-ratio: 1.62 / 1;
  margin-bottom: 13rem;
  @media screen and (max-width: 768px) {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
  }
  .text-section {
    z-index: 2;
    transition: all 0.85s ease;
    @media screen and (max-width: 768px) {
      padding: 4rem 1.5rem;
      max-width: 100%;
      flex-direction: column;
      gap: 1rem;
      order: 2;
      bottom: 2.5rem;
      border-radius: 1.5rem;
    }
    @media screen and (max-width: 550px) {
      padding: 3.5rem 1.5rem;
      border-radius: 1.5rem;
      bottom: 1.2rem;
    }
    .right-bar {
      right: -11.5rem;
      top: 1.6rem;
      transition: all 0.85s ease;
      @media screen and (max-width: 768px) {
        right: -1.5rem;
        top: 1rem;
        width: 7.375rem;
      }
    }
    .left-bar {
      bottom: 8rem;
      left: -4.5rem;
      transition: all 0.85s ease;
      @media screen and (max-width: 768px) {
        bottom: 1rem;
        left: -1.5rem;
        width: 6.375rem;
      }
    }
    padding: 5rem;
    gap: 6.25rem;
    position: relative;
    bottom: -9.5rem;
    border-radius: 3.125rem;
    background: var(--cu-white-900);
    box-shadow: 0px 4px 36px 0px var(--cu-grey-trans-300);
    max-width: 58.6dvw;
    h2 {
      font-size: clamp(3rem, 3.75rem, 4rem);
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -2.4px;
      color: var(--cu-black-900);
      text-align: left;
      z-index: 1;
      @media screen and (max-width: 550px) {
        font-size: clamp(2.5rem, 2.9rem, 3rem);
      }
    }
    p {
      color: var(--cu-black-900);
      font-size: clamp(0.75rem, 1rem, 1.25rem);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-align: left;
    }
    .gradient-see-more-btn {
      display: flex;
      padding: 0.625rem 1.875rem;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      font-size: 0.875rem;
      border-radius: 0.625rem;
      font-size: clamp(0.75rem, 1rem, 1.25rem);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      position: relative;
      color: var(--cu-white-900);
      transition: all 0.3s ease;
      overflow: hidden;
      background: linear-gradient(
        94deg,
        #258df4 -3.86%,
        #417df6 7.42%,
        #8858fc 36.14%,
        #a44aff 49.48%,
        #a648f8 53.58%,
        #ac42e6 59.74%,
        #b73ac8 66.92%,
        #c62e9f 75.12%,
        #d91e69 84.36%,
        #ef0c2a 93.59%,
        #f00 98.72%
      );
      &:after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 0.625rem;
        transition: all 0.3s ease;
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
      }
      &:hover {
        color: #fff;
        border: none;
        background: transparent;
      }
      &:hover:after {
        background: linear-gradient(
          94deg,
          #258df4 -3.86%,
          #417df6 7.42%,
          #8858fc 36.14%,
          #a44aff 49.48%,
          #a648f8 53.58%,
          #ac42e6 59.74%,
          #b73ac8 66.92%,
          #c62e9f 75.12%,
          #d91e69 84.36%,
          #ef0c2a 93.59%,
          #f00 98.72%
        );
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  .img-wrapper {
    border-radius: 3.125rem;
    transition: all 0.85s ease;
    background: linear-gradient(
      271deg,
      #258df4 -24.9%,
      #2b90f4 -20.78%,
      #9ccbfa 45.07%,
      #e3f0fd 91.72%,
      #fff 112.3%
    );
    width: 62.7dvw;
    margin-left: auto;
    aspect-ratio: 1.5/1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      width: 100%;
      border-radius: 1rem;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

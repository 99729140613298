
.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .video-button,
  .video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    // --video-width: 80vw;
    // width: var(--video-width);
    // height: calc(var(--video-width) / 1.77) ;
  }

  .video-button {
    cursor: pointer;
    //   clip-path: polygon(30% 20%, 70% 20%, 70% 80%, 30% 80%);
    border-radius: 2rem;
    overflow: hidden;
    transition: 0.6s cubic-bezier(0.6, 0, 0.4, 1);
    @media screen and (max-width: 768px) {
      border-radius: 0.75rem;
    }
    &.active{
      .play-button-aperture-left {
        clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
      }
      .play-button-aperture-top-right {
        clip-path: polygon(100% 0%, 100% 0%, 100% 0%);
      }
      .play-button-aperture-bottom-right {
        clip-path: polygon(100% 100%, 100% 100%, 100% 100%);
      }
      .actual-video{
       // transform: scale(1.2);
      }
      //     clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  .video-container {
    overflow: hidden;
  }

  .actual-video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: 0.6s linear;
    transform: scale(1.6);
  }
  @mixin play-button-aperture() {
    position: absolute;
    background: var(--cu-white-trans-700);
    transition: 0.5s 0.1s cubic-bezier(0.6, 0, 0.4, 1);
  }

  .play-button-aperture-left {
    @include play-button-aperture();
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0%, 40% 0%, 40% 100%, 0% 100%);
  }

  .play-button-aperture-top-right {
    @include play-button-aperture();
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 80%);
  }
  .play-button-aperture-bottom-right {
    @include play-button-aperture();
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 100%, 100% 100%, 100% 20%);
  }
}

.how-it-work {
  .gradient-nine {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    aspect-ratio: 3 / 1;
    top: -18%;
    @media screen and (max-width: 768px) {
      aspect-ratio: 3 / 1.25;
    }
    @media screen and (max-width: 550px) {
      aspect-ratio: 2 / 1;
      top: -15%;
    }
  }
  .right-bar {
    top: 3.2rem;
    right: -28%;
    width: 28.5dvw;
    aspect-ratio: 1 / 0.088;
    transition: all 0.85s ease;
    @media screen and (max-width: 768px) {
      top: 2.2rem;
      right: -1.5rem;
    }
    @media screen and (max-width: 550px) {
      top: 1.2rem;
    }
  }
  .left-bar {
    bottom: 5.3rem;
    left: -11%;
    width: 13.5dvw;
    transition: all 0.85s ease;
    @media screen and (max-width: 768px) {
      bottom: 2rem;
      left: -1.5rem;
    }
    @media screen and (max-width: 768px) {
      bottom: 1rem;
    }
  }
  .bar-gradient {
    @media screen and (max-width: 768px) {
      width: 12dvw;
      aspect-ratio: unset;
    }
    //   @media screen and (max-width:550px) {
    //     font-size: clamp(2rem, 2.9rem, 3rem);
    //     font-weight: 400;
    //     line-height: 3rem;
    // }
  }
  .text-wrapper {
    padding: 5.5dvw 10dvw 5dvw 6dvw;
    border-radius: 3rem;
    box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.15);
    max-width: 65.3dvw;
    z-index: 2;
    background: var(--cu-white-900);
    transition: all 0.85s ease;
    @media screen and (max-width: 768px) {
      z-index: 3;
      max-width: 100%;
      padding: 3rem 1.31rem;
      order: 2;
      bottom: 4.5rem;
      border-radius: 2.5rem;
    }
    @media screen and (max-width: 550px) {
      bottom: 3.5rem;
      border-radius: 1.5rem;
    }
    h1 {
      font-size: clamp(4rem, 6.25rem, 6.5rem);
      font-style: normal;
      font-weight: 500;
      line-height: 80%;
      color: var(--cu-black-900);
      text-align: left;
      @media screen and (max-width: 768px) {
        font-size: clamp(3rem, 3.25rem, 6rem);
      }
      @media screen and (max-width: 550px) {
        font-size: clamp(2.5rem, 2.8rem, 3rem);
      }
    }
    p {
      font-size: clamp(1rem, 1.5rem, 1.75rem);
      font-style: normal;
      font-weight: 400;
      text-align: left;
      white-space:pre-line;
      @media screen and (max-width: 768px) {
        font-size: clamp(1rem, 1.25rem, 1.5rem);
      
      }
      @media screen and (max-width: 550px) {
        font-size: clamp(0.75rem, 1rem, 1.25rem);
      }
    }
  }
  .img-wrapper {
    z-index: 2;
    width: 81dvw;
    aspect-ratio: 1.78 / 1;
    position: relative;
    bottom: 4rem;
    align-self: end;
    transition: all 0.85s ease;
    .screenshot-img{
      width: 100%;
      height: 100%;
      border-radius: 3rem;
      @media screen and (max-width: 768px) {
        border-radius: 2rem;
      }
      @media screen and (max-width: 550px) {
        border-radius: 1rem;
      }
    }
    @media screen and (max-width: 768px) {
      position: static;
      bottom: 0;
      width: 100%;
      padding: 0rem 1.2rem;
    }
  }
}

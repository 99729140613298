html {
    // height: -webkit-fill-available;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling:touch;
    overflow: auto;
     height: 100%;
}
::-webkit-scrollbar {
    width: 0.1rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 1rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--cu-grey-650);
    border: 1px solid transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

body,
#root {
   // height: -webkit-fill-available;
    // height: 100vh;
}

body,
html {
    font-family: ZenKaku;

    // font-size: 10px;
    @screen md {
        font-size: 10px;
    }

    @screen lg {
        font-size: 12px;
    }

    // @screen xl {
    //     font-size: 14px;
    // }

    @screen 2xl {
        font-size: 16px;
    }
}

body {
    overflow-y: visible;
    position: relative;
    height: unset;
}

* {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.scroller {
    padding-right: 0.25rem !important;
}

.header-wrapper {
    scroll-behavior: smooth;
}

@media screen and (max-width: 550px) {
    // .collect-full {
    //     min-height: calc(100vh - 5rem);
    // }
}

.border-rght-hgt {
    border-right: 1px solid var(--cu-black-20);
    padding-right: 1rem;
    // height: 1.1rem;

    @media screen and (max-width: 326px) {
        height: 0.9rem !important;
    }
}

.border-left {
    border-left: 1px solid var(--cu-black-20);
    padding-left: 1rem;
    height: 1.1rem;
}

.imghgt {
    height: 100%;
    width: 100%;
    @media screen and (max-width: 326px) {
        height: 100%;
    width: 100%;
    }
}

.viewButtons {
    img {
        @media screen and (max-width: 326px) {
            height: 0.9rem !important;
        }

    }
}

.border-rght {
    border-right: 1px solid var(--cu-black-20);
    padding-right: 1rem;
}

.skelton-banner {
    width: 100%;
    height: 3rem;

    @media screen and (max-width: 550px) {
        margin-top: -4px;
        z-index: 1;
        height: 2.2rem;
        .react-loading-skeleton{
            height: 2.2rem !important;
        }
    }
}

.skelton-card {
    grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
    display: grid;
    width: 100%;
    gap: 0.875rem;
    padding: 1rem 2.4rem 1rem 1rem;

    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        padding: 2rem 1rem 1rem 1rem;
    }
}
.skelton-card-discover-collection {
    grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
    display: grid;
    width: 100%;
    gap: 0.875rem;
    padding: 1rem 2.4rem 1rem 1rem;
    margin-top: 2rem;

    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        padding: 2rem 1rem 1rem 1rem;
    }
}
.skelton-card-community {
    display: grid;
    width: 100%;
    
    gap: 0.525rem;
    padding: 1.5rem 0rem 0rem 0rem;
    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        padding: 2rem 1rem 1rem 1rem;
    }
}



.framshop-skelton {
    @media screen and (max-width: 550px) {
        .skeltonMainClass {
            right: 0.625rem !important;
            left: 0.625rem !important;
            top: 0.625rem !important;

        }
    }
}

.col-frame-skelton {
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
    display: grid;
    gap: 0.5rem;
}

.brdr-rght {
    border-right: 1px solid var(--cu-black-20);
    padding-right: 1rem;
}

.page-shadow {
    box-shadow: inset 0.125rem 0.125rem 0.25rem var(--cu-black-900);
    @media screen and (max-width: 550px) { 
        box-shadow: unset;
    }
}
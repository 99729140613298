
@font-face {
    font-family: 'ZenKaku';
    src: url(~/src/Assets/Fonts/ZenKakuGothicAntique-Medium.ttf) ;
      
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'ZenKaku';
    src: url(~/src/Assets/Fonts/ZenKakuGothicAntique-Regular.ttf);
      
    font-weight: 400;
    font-style: normal;
  }
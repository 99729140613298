.grid-bg-and-text {
    aspect-ratio: 1.9/1;
    display: flex;
    align-items: center;

    .bg-image {
        position: absolute;
        inset: 0;
    }

    .particles {
        position: absolute;
        z-index: 1;
        transition: all 1s ease;

        &:first-child {
            top: 0dvh;
            left: 7dvw;
        }

        &:nth-child(2) {
            top: 11dvh;
            left: 44dvw;
        }

        &:nth-child(3) {
            top: 0dvh;
            right: 18dvw;
        }

        &:nth-child(4) {
            top: 46dvh;
            right: 8dvw;
        }

        &:nth-child(5) {
            bottom: 19dvh;
            left: 12dvw;
        }

        &:nth-child(6) {
            bottom: -7dvh;
            right: 25dvw;
        }

        &:nth-child(7) {
            bottom: -60dvh;
            right: 15dvw;
        }
        @media screen and (max-width:768px) {
            aspect-ratio: 1;
           &.md{
            max-width: 7dvw;
           }
           &.sm{
            max-width: 3.5dvw;
           }
           &:nth-child(2) {
            top: 10dvw;
        }

        &:nth-child(3) {
            top: 3dvw;
        }
        &:nth-child(4) {
            top: 53dvw;
        }
        &:nth-child(5) {
            top: 50dvw;
            left: 4dvw;
        }
        &:nth-child(6) {
            bottom: -5dvw;
            left: 34dvw;
            right: unset;
        }
        &:nth-child(7) {
            bottom: -18dvw;
            right: 33dvw;
        }
        }
    }

    .gradient-left {
        position: absolute;
        left: 0;
        z-index: 2;
    }

    .text-wrapper {
        z-index: 2;
        position: relative;

        @media screen and (max-width:768px) {
            margin: 4rem 2rem;
        }

        h1 {
            font-size: clamp(2.9rem, 6.25rem, 6.75rem);
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -4px;
            text-align: left;

            @media screen and (max-width:768px) {
                font-size: clamp(2rem, 2.75rem, 3.5rem);
                text-align: center;
            }
        }

        h5 {
            font-size: clamp(1.3rem, 2.9rem, 3rem);
            font-weight: 500;
            text-align: left;

            @media screen and (max-width:768px) {
                font-size: clamp(1rem, 1.3rem, 3rem);
                text-align: center;
            }
        }

        p {
            font-size: clamp(1.3rem, 1.5rem, 1.75rem);
            text-align: left;
            max-width: 24.6rem;
            margin-left: auto;

            @media screen and (max-width:768px) {
                font-size: clamp(1rem, 1.3rem, 3rem);
                text-align: center;
                max-width: 100%;
                margin-left: unset;
            }
        }
    }
}
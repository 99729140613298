.creators-hero-heading {
    position: relative;
    padding-bottom: 20dvh;
    @media screen and (max-width:768px) {
      padding-bottom: 4.5rem;
    }
    .text-wrapper {
      padding-top: 7%;
      @media screen and (max-width:768px) {
        padding: 4.5rem 3rem 0px 3rem;
      }
      @media screen and (max-width:768px) {
        padding: 4.5rem 2rem 0px 2rem;
      }
      h1 {
        color: var(--cu-black-900);
        font-size: clamp(4.75rem, 11.125rem, 11.25rem);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -7.149px;
        max-width: 69rem;
        margin: auto;
        line-height: 10.93rem;
        transition: all 0.85s ease;
        @media screen and (max-width:768px) {
          font-size: clamp(4.75rem, 6.125rem, 7rem);
          line-height: 7rem;
        }
        @media screen and (max-width:550px) {
          font-size: clamp(4.5rem, 4.75rem, 6.125rem);
          line-height: 5rem;
        }
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
          @media screen and (max-width:550px) {
            text-align: left;
          }
        }
      }
    }
  }
  
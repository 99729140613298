.gradient-bg-section {
    background-image: url("../../../../../Assets/Images/creators/gradient-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    padding: 28dvw 4rem;
    @media screen and (max-width: 768px) {
        padding: 15rem 2rem;
    }
    @media screen and (max-width: 550px) {
        padding: 10rem 2rem;
    }

    .particles {
        position: absolute;
        z-index: 1;
        transition: all 1s ease;

        &:first-child {
            bottom: 130dvh;
            left: 24dvw;
        }

        &:nth-child(2) {
            bottom: 105dvh;
            left: 10dvw;
        }

        &:nth-child(3) {
            bottom: 38dvh;
            left: 22dvw;
        }

        &:nth-child(4) {
            bottom: 29dvh;
            left: 37dvw;
        }

        &:nth-child(5) {
            bottom: 14dvh;
            right: 42dvw;
        }
        @media screen and (max-width: 768px) {
           display: none;
        }
    }
}
.app-items-list-section {
    h1 {
        font-size: clamp(1.875rem, 2.5rem, 3rem);
        color: var(--cu-black-900);
        line-height: 99.2%;
        letter-spacing: -1.574px;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            max-width: 15.25rem;
            font-size: clamp(1.5rem, 1.875rem, 2rem);
            margin: 0 auto;
          }
    }

    padding: 10rem 3dvw 0rem 3dvw;
    @media screen and (max-width: 768px) {
        padding: 6rem 3dvw 0rem 3dvw;
      }
    .app-item {
        .particles {
            position: absolute;
            z-index: 1;
            transition: all 0.85s ease;

            &:first-of-type {
                right: 5dvw;
                top: 16dvw;
            }

            &:nth-of-type(2) {
                right: 22dvw;
                top: 24.5dvw;
            }

            &:nth-of-type(3) {
                right: 13dvw;
                top: 35dvw;
            }

            &:nth-of-type(4) {
                left: 29dvw;
                top: 43dvw;
            }

            &:nth-of-type(5) {
                left: 3.5dvw;
                top: 51dvw;
            }

            &:nth-of-type(6) {
                left: 45dvw;
                top: 73dvw;
                @media screen and (max-width: 550px) {
                    left: unset;
                    top: 180dvw;
                    right: 33dvw;
                    width: 2.7rem;
                }
            }

            &:nth-of-type(7) {
                right: 33dvw;
                top: 78dvw;
                @media screen and (max-width: 550px) {
                    right: unset;
                    top: 233dvw;
                    left: 4dvw;
                    width: 2.7rem;
                }
            }

            &:nth-of-type(8) {
                left: 10dvw;
                top: 84dvw;
            }

            &:nth-of-type(9) {
                left: 4dvw;
                top: 109dvw;

            }

            &:nth-of-type(10) {
                right: 8dvw;
                top: 108dvw;
            }

            &:nth-of-type(11) {
                right: 43dvw;
                top: 121dvw;
            }

            &:nth-of-type(12) {
                left: 7dvw;
                top: 127dvw;
            }

            &:nth-of-type(13) {
                right: 23dvw;
                top: 124dvw;
                @media screen and (max-width: 550px) {
                    right: 12dvw;
                    top: 335dvw;
                }
            }
            @media screen and (max-width: 768px) {
                display: none;
                &:is(:nth-of-type(6),:nth-of-type(7),:nth-of-type(13)){
                    display: inline-block;
                }
              }
        }

        .app-items-cards {

            z-index: 3;
            position: absolute;

            &:first-child {
                left: 0;
                top: 27dvw;
                @media screen and (max-width: 550px) {
                    left: unset;
                    top: 58dvw;
                    right: 2dvw;
                }
            }

            &:nth-child(2) {
                left: 14dvw;
                top: 14dvw;
                @media screen and (max-width: 550px) {
                    left: 2dvw;
                    top: 42dvw;
                }
            }

            &:nth-child(3) {
                right: 28dvw;
                top: 11dvw;
                @media screen and (max-width: 550px) {
                    right: 2dvw;
                }
            }

            &:nth-child(4) {
                right: 38dvw;
                top: 42dvw;
                @media screen and (max-width: 550px) {
                    right: unset;
                    top: 94dvw;
                    left: 2dvw;
                }
            }

            &:nth-child(5) {
                left: 2dvw;
                top: 61dvw;
                @media screen and (max-width: 550px) {
                    left: 7dvw;
                    top: 195dvw;
                
                }
            }

            &:nth-child(6) {
                left: 13dvw;
                top: 55dvw;
                @media screen and (max-width: 550px) {
                    right: 2dvw;
                    left: unset;
                    top: 93dvw;
                }
            }

            &:nth-child(7) {
                right: 35dvw;
                top: 54dvw;
                @media screen and (max-width: 550px) {
                    right: 4dvw;
                    top: 313dvw;
                }
            }

            &:nth-child(8) {
                right: 6dvw;
                top: 44dvw;
                @media screen and (max-width: 550px) {
                    right: unset;
                    left: 2dvw;
                    top: 135dvw;
                }
            }

            &:nth-child(9) {
                right: 14dvw;
                top: 74dvw;
                @media screen and (max-width: 550px) {
                    right: 6dvw;
                    top: 147dvw;
            
                }
            }

            // &:nth-child(10) {
            //     right: 3dvw;
            //     top: 78dvw;

            // }

            &:nth-child(10) {
                left: 22dvw;
                top: 87dvw;
                @media screen and (max-width: 550px) {
                    right: 7dvw;
                    left: unset;
                    top: 266dvw;
                }

            }

            &:nth-child(11) {
                right: 26dvw;
                top: 90dvw;
                @media screen and (max-width: 550px) {
                    right: unset;
                    left: 2dvw;
                    top: 263dvw;
                }
            }

            &:nth-child(12) {
                right: 8dvw;
                top: 92dvw;
                @media screen and (max-width: 550px) {
                    left: 20dvw;
                    top: 313dvw;
                }

            }

            &:nth-child(13) {
                left: 15dvw;
                top: 105dvw;
                @media screen and (max-width: 550px) {
                    right: 2dvw;
                    left: unset;
                    top: 208dvw;
                }
            }
        }
    }
}
.team-card {
    display: flex;
    flex-direction: column;

    &.right-align {
        .details {
            left: 7dvw;
            @media screen and (max-width: 550px) {
                left: 20dvw;
            }
        }
    }

    &.left-align {
        .details {
            left: -7dvw;
            @media screen and (max-width: 550px) {
                left: -20dvw;
            }
        }
    }
    @media screen and (max-width: 550px) {
       &:nth-child(even){
        .details {
            left: 20dvw !important; 
        }
       }
       &:nth-child(odd){
        .details {
            left: -20dvw !important; 
        }
       }
    }
    .image-wrapper {
        border-radius: 2rem;
        overflow: hidden;
        width: 24.1dvw;
        aspect-ratio: 0.92 / 1;
        transition: all 0.85s ease;
        background: var(--cu-bg-grey);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media screen and (max-width: 768px) {
            width: 32.1dvw;
        }
        @media screen and (max-width: 550px) {
            width:65dvw;
            border-radius: 1.1875rem;
        }
    }

    .details {
        transition: all 0.75s ease;
        display: flex;
        flex-direction: column;
        width: 20.8dvw;
        gap: 1.875rem;
        padding: 2.85dvw 2.1dvw 4dvw 2.1dvw;
        border-radius: 1.25rem;
        background: var(--cu-white-900);
        box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.15);
        top: -1dvw;
        position: relative;
        @media screen and (max-width: 768px) {
            gap:1.1rem;
            width: 34dvw;
        }
        @media screen and (max-width: 550px) {
            padding: 2rem 2.15rem;
            width: 62dvw;
            border-radius: 0.75rem;
        }
        h1 {
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -2.4px;
            color: var(--cu-black-900);
            font-size: clamp(2rem, 3dvw, 4rem);
            
        @media screen and (max-width: 768px) {
            font-size: clamp(1.85rem, 2.25dvw, 3rem);
        }
        }

        h2 {
            font-weight: 700;
            line-height: 140%;
            font-size: clamp(0.875rem, 1.5rem, 2rem);
            color: var(--cu-blue-navy);
            @media screen and (max-width: 768px) {
                font-size: clamp(0.5rem, 0.875rem, 1.5rem);
            }
        }

        p {
            color: var(--cu-black-900);
            line-height: 140%;
            font-size: clamp(0.875rem, 1.4rem, 2rem);
            @media screen and (max-width: 768px) {
                font-size: clamp(0.5rem, 0.875rem, 1.25rem);
            }
        }
    }
}
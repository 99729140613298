.accordion-arrow {
    display: flex;
    position: relative;
    overflow: hidden;
    font-size: .75rem;
    color: var(--neutral-500);
    &::after {
        content: "";
        // background: url('/Assets/Icons/accordion-arrow-new.svg');        
        position: absolute;
        right: .3rem;
        transition: .5s ease;
        transform-origin: center;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 1rem;
        height: 1rem;
    }

    &-expanded {
        color: var(--cu-white-700);
        font-size: .75rem;
        &::after {
            transform: rotateZ(90deg);
        }
    }

    &-collapsed {
        &::after {
            transform: rotateZ(0deg);
        }
    }
}

.expander-content {
    padding-left: 0.5rem;
}

.accordion-with-edit{
    .accordion-arrow{
        &::after {
            content: none;
        }
    } 
}
.title-and-description {
    @media screen and (max-width: 768px) {
        padding: 0rem 2rem;
    }

    h1 {
        font-size: clamp(2.5625rem, 3.1875rem, 3.5rem);
        color: var(--cu-black-900);
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -2.042px;

        @media screen and (max-width: 768px) {
            font-size: clamp(2rem, 2.5rem, 3rem);
        }
    }

    p {
        color: var(--cu-black-900);
        font-size: clamp(1.1875rem, 1.5rem, 2rem);
        line-height: 140%;
        
        @media screen and (max-width: 768px) {
            font-size: clamp(1.5rem, 1.1875rem, 2rem);
        }
    }
}
.cards-section {
    grid-template-columns: repeat(4, 1fr);
    padding: 0rem 7dvw;

    @media screen and (max-width: 1024px) {
        padding: 0rem 4rem;
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media screen and (max-width: 768px) {
        padding: 0rem 2rem 5rem 2rem;
    }
    @media screen and (max-width: 550px) {
        gap: 5rem;
        margin: 0rem 0rem 5rem 0rem;
        grid-template-columns: repeat(1, 1fr);
    }
    &:hover .card:not(:hover){
        filter: blur(8px);    
    }
    .card {
        height: fit-content;
        padding: 1.125rem;
        border-radius: 1rem;
        &:nth-child(3) {
            margin-top: 8dvw;
        }

        &:nth-child(2) {
            margin-top: 16dvw;
        }

        &:first-child {
            margin-top: 24dvw;
        }

        .card-image {
            aspect-ratio: 0.78 / 1;
            border-radius: 1rem;
        }
        @media screen and (max-width: 550px) {
        
            margin-top: 0rem !important;
        }
    }
}